﻿<script lang="ts">
    import {Button, Modal, Row} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {applicationContextKey} from 'PublicationDependencies';
    import * as Style from 'Components/Style';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import {getContext, onMount} from 'svelte';
    import type DependencyContainer from 'Lib/DependencyContainer';
    import {Column, PasswordField, Text} from 'Components/UI';
    import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';

    const translationContext = translations.pages.presentation.passwordPromptDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const {passwordErrorObservable, showLoadingIndicatorObservable} = applicationContext.get(PresentationContext);

    export let passwordField: HTMLInputElement | undefined = undefined;
    export let scenarioId: Nullable<string> = undefined;
    let password: string | undefined = undefined;

    $: if ($passwordErrorObservable) {
        password = undefined;
    }

    function clearError(): void {
        $passwordErrorObservable = undefined;
    }

    onMount(() => passwordField?.focus());
    export let callback: (p: string) => void;
</script>
<form on:submit|preventDefault={() => {callback(password); password=undefined;}}>
    <Modal
            isShown={scenarioId !== undefined}
            title={translationContext.title}
            showLoading={$showLoadingIndicatorObservable}>
        <svelte:fragment slot="content">
            <Column width="300px" gap="20px">
                <p>{translationContext.description}</p>
                {#if $passwordErrorObservable}
                    <Text color={Style.colors.negative}>{$passwordErrorObservable}</Text>
                {/if}
                <PasswordField bind:inputElement={passwordField} bind:value={password}
                               placeholder={translationContext.placeholder} on:keydown={clearError}
                               on:focus={clearError}
                               isDisabled={$showLoadingIndicatorObservable}/>
            </Column>
        </svelte:fragment>
        <svelte:fragment slot="buttons">
            <Row horizontalAlign="end">
                <Button
                        backgroundColor={Style.colors.primary}
                        minWidth={Style.unset}
                        isDisabled={$showLoadingIndicatorObservable}
                >
                    {translationContext.confirmAction}
                </Button>
            </Row>
        </svelte:fragment>
    </Modal>
</form>
