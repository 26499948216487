<script lang="ts">
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import type { Nullable } from 'Lib/Utils/Nullable';

    export let color: Nullable<string> = 'red';
    export let textColor: Nullable<string> = 'white';

    $: style = {
        backgroundColor: color,
        color: textColor,
    };
</script>

<div class="clip">
    <div
        class="banner"
        use:applyStyle={style}>
        <slot />
    </div>
</div>

<style>
    .clip {
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: clip;
        z-index: 9999;
        width: 150px;
        height: 150px;
        pointer-events: none;
    }
    .banner {
        position: absolute;
        left: -70px;
        bottom: 35px;
        width: 250px;
        height: 40px;
        transform: rotate(45deg);
        z-index: 9999;
        background-color: red;
        opacity: 0.8;
        text-align: center;
        line-height: 40px;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        pointer-events: none;
        box-shadow: 7px 7px 7px 0 rgba(0, 0, 0, 0.2);
    }
</style>
