﻿<script lang="ts">
    import ActiveGrafLogo from 'Assets/Icons/ActiveGraf_Logo.png';
    import {Button} from 'Components/UI';
    import translations from 'Assets/i18n';
    import URLParamsUtils from 'PresentationBoostrap/Lib/URLParamsUtils';

    export let scenarioId: string;

    export let action: (e: MouseEvent) => void;
    
    const thumbnailSlideNumber = URLParamsUtils.getInitialSlideNumber() ?? 0;
    
    let thumbnailSource: string = `/static/${scenarioId}/${thumbnailSlideNumber}.png`;
</script>

<img class="background" src={thumbnailSource} alt=""/>
<div class="start-overlay">
    <img src={ActiveGrafLogo}
         alt="ActiveGraf Logo"/>
    <Button on:click={action}>
        <span class="mdi mdi-play"/>
        {translations.pages.presentation.startPresentation}
    </Button>
</div>

<style>
    img {
        max-width: 100%;
    }

    .start-overlay, .background {
        position: fixed;
        width: 100%;
        height: 100%;
    }
    
    .background{
        background-color: white;
        filter: blur(10px);
        object-fit: contain;
    }
    
    .start-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.8rem;
    }
</style>
