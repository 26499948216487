﻿import SingletonDependency from 'Lib/SingletonDependency';
import type DependencyContainer from 'Lib/DependencyContainer';

export default class UserActivityService extends SingletonDependency {
    private readonly eventTypes: (keyof DocumentEventMap)[] = ['click', 'mousemove', 'touchstart'];
    private readonly timeout = 2 * 60 * 1000;

    private lastEventTimestamp = Number.MIN_VALUE;
    private timer = 0;

    constructor(applicationContext: DependencyContainer) {
        super(applicationContext);
    }

    public start(targets: EventTarget[]) {
        if (this.timer != 0) return;

        this.setEventListeners(targets);

        // @ts-expect-error: setInterval returns a number, not a Timer
        this.timer = setInterval(this.checkActivity.bind(this), this.timeout);

        console.info(`[${UserActivityService.name}] Started`);
    }

    private setEventListeners(targets: EventTarget[]) {
        for (const target of targets) {
            for (const eventType of this.eventTypes) {
                target.addEventListener(eventType, this.onActivityEvent.bind(this));
            }
        }
    }

    public stop() {
        if (this.timer == 0) return;

        clearInterval(this.timer);
        this.timer = 0;

        console.info(`[${UserActivityService.name}] Stopped`);

        location.reload();
    }

    private async onActivityEvent(e: Event) {
        this.lastEventTimestamp = new Date().getTime();
    }

    private checkActivity() {
        const now = new Date().getTime();

        if (now - this.lastEventTimestamp > this.timeout) {
            console.info(`[${UserActivityService.name}] Timeout ${now - this.lastEventTimestamp}`);
            this.stop();
        }
    }
}
